<template>
  <div
    class="mb-"
    style="color: #555"
  >
    <b-card
      class=""
      body-class="pb-"
    >
      <div
        v-if="comanda && comanda.dt_fechada"
        class="alert bg-danger text-white w-100 p-2 text-center"
      >
        <h4>
          <b-icon-exclamation-circle-fill></b-icon-exclamation-circle-fill>
          COMANDA FECHADA
        </h4>
      </div>
      <b-card-title>
        <h4 class="mb-2 px-0 px-2">
          <div class="row">
            <div class="col-12 col-lg-auto px-0 px-3">
              <div>
                {{
                  `${comanda ? "" : mesaObj.mesa + ""}  
            `
                }}
              </div>
            </div>
            <div
              class="col-12 px-0"
              v-if="comanda"
            >
              <i class="fa fa-barcode mr-1"></i>
              {{ getComandaNome(comanda, 0) }}
            </div>
            <div
              class="col-12 mb-2 px-0 px-2"
              v-if="comanda"
            >
              <span
                class=""
                style="font-size: 12px"
                v-if="
                  getComandaNome(comanda, 1) &&
                  getComandaNome(comanda, 1).trim() != ''
                "
              >
                <div class="pt-2">
                  <i class="fa fa-user mr-1"></i>
                  {{ getComandaNome(comanda, 1) }}

                  <i
                    :class="
                      'fa fa-phone' +
                      (!$store.state.isMobile ? ' ml-2 mr-1' : '')
                    "
                  ></i>
                  {{ getComandaNome(comanda, 2) }}
                </div>
              </span>
              <hr />
            </div>

            <div
              v-if="
                mesaObj &&
                mesaObj.reserva &&
                mesaObj.reserva.id &&
                mesaObj.reserva.id > 0
              "
              style="font-size: 10px"
              class="col px-0"
            >
              <b-icon-calendar /> Reserva de {{ mesaObj.reserva.nome }}
            </div>

            <div
              class="col-12 col-lg-auto px-0 px-3"
              style="font-size: 10px"
            >
              Conta nº {{ mesaObj.id || "" }}
              <div>
                <i class="fa fa-calendar mr-1"></i
                >{{ mesaObj.dt_aberta | moment("DD/MM/YYYY HH:mm") }}
              </div>
            </div>
          </div>
        </h4>
      </b-card-title>

      <div class="d-flex justify-content-between mt-2">
        <div>Subtotal</div>
        <div>{{ subtotal | currencyMask }}</div>
      </div>
      <div
        class="d-flex justify-content-between"
        v-if="taxa_servico"
      >
        <div>
          Taxa Serviço
          <span v-if="comanda && comanda.id">({{ taxa_servico }}%)</span>
        </div>
        <div>{{ taxa | currencyMask }}</div>
      </div>
      <div class="d-flex justify-content-between font-weight-bold">
        <div>Total</div>
        <div>{{ total | currencyMask }}</div>
      </div>
      <div class="d-flex justify-content-between font-weight-bold mt-3 menor">
        <div>Pago</div>
        <div>{{ pago | currencyMask }}</div>
      </div>

      <div
        class="d-flex justify-content-between font-weight-bold menor"
        style="color: red"
      >
        <div>Restante</div>
        <div>{{ restante | currencyMask }}</div>
      </div>

      <div
        class="d-flex justify-content-between font-weight-bold menor"
        style="color: #ccc"
        v-if="mesaVisu"
      >
        <div>Pago Rateado</div>
        <div>{{ pagoRateado | currencyMask }}</div>
      </div>
      <div
        class="d-flex justify-content-end"
        style="position: absolute; bottom: -25px; right: -0px"
        v-if="!mesaObj.dt_fechada && isMobile"
      >
        <b-btn
          size="lg"
          variant="info"
          class="mr-1"
          @click="addItem"
          v-if="showFechar"
        >
          <i class="fa fa-shopping-cart"></i> Item
        </b-btn>

        <b-btn
          size="lg"
          variant="info"
          class="mr-1"
          @click="fechar"
          v-if="showFechar"
        >
          <i class="fas fa-clipboard-check"></i> Fechar
        </b-btn>

        <b-btn
          v-if="showButtons"
          size="lg"
          variant="danger"
          class="mr-1"
          @click="removeTaxa"
          ><b-icon-percent
        /></b-btn>
        <b-btn
          v-if="showButtons"
          size="lg"
          variant="success"
          class="mr-1"
          @click="addPgto"
          ><b-icon-currency-dollar
        /></b-btn>
      </div>
    </b-card>
    <b-card
      class="mt-2 p-0"
      body-class="p-0"
      v-if="mesaObj.pagamentos && !isMobile"
    >
      <div
        v-if="!mesaObj.pagamentos || mesaObj.pagamentos.length <= 0"
        class="p-4 text-center"
      >
        <small>Nenhum pagamento registrado ainda :(</small>
      </div>
      <div v-else>
        <b-card-title>
          <h5 class="justify-content-between d-flex bg-light p-2">
            <div class="mb-2">Total Pago</div>
            <div class="mb-2">{{ pago | currencyMask }}</div>
          </h5>
        </b-card-title>
        <div
          class="px-3 pb-2"
          style="color: #444"
        >
          <div
            v-for="(pg, idx) in pagamentos"
            :key="idx"
          >
            <div class="">
              <div
                class="row mt-2 selPGBt"
                @click="editPagamento(pg)"
              >
                <div class="col-12 d-flex justify-content-between">
                  <div>
                    <small style="color: #aaa"
                      ><b>{{ pg.forma }}</b></small
                    >
                    <div style="font-size: 8px">
                      {{ pg.created_at | moment("DD/MM/YYYY HH:mm") }}
                      <span v-if="pg.comanda">
                         - {{ pg.comanda }}
                      </span>
                    </div>
                  </div>

                  <span
                    style="font-size: 20px; font-weight: 700; color: #666"
                    class="pt-1"
                    >{{ pg.valor | currencyMask }}</span
                  >
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <div class="m-3">
        <b-btn
          variant="success"
          @click="addPgto"
          block
        >
          Adicionar Pagamento
        </b-btn>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    mesaObj: Object,
    mesaVisu: Number,
    getMesaNome: Function,
    comanda: Object,
    editPagamento: Function,
    showFechar: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    showPagamentos: {
      type: Boolean,
      default: false,
    },
    reabrirMesa: Function
  },
  mounted() {
    // console.log("COMANDASEL", this.comanda);
  },
  data() {
    return {
      isMobile: this.$store.state.isMobile,
    };
  },
  watch: {
    "$.props": {
      deep: true,
      handler() {
        console.log("COMAND", this.comanda);
      },
    },
  },
  computed: {
    pagamentos(){
      return this.mesaObj.pagamentos.filter(
        (x) =>
          (this.mesaVisu ? x.cod_mesa == this.mesaVisu && !x.ratear : true) &&
          (this.comanda ? x.comanda_id == this.comanda.id : true)
      );
    },
    subtotal() {
      return this.mesaObj.pedidos
        .filter((x) => !x.excluido || x.excluido == 0)
        .filter(
          (x) =>
            (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
            (this.comanda ? x.comanda_id == this.comanda.id : true)
        )
        .reduce((ret, vl) => {
          ret += vl.vl_total;
          return ret;
        }, 0);
    },
    taxa_servico() {
      return this.comanda
        ? this.comanda.taxa_servico
        : this.mesaObj.taxa_servico;
    },
    taxa() {
      return this.comanda
        ? this.comanda.vl_taxa_servico
        : this.mesaObj.vl_taxa_servico;
    },
    total() {
      return this.subtotal + this.taxa;
    },
    pago() {
      return this.mesaObj.pagamentos
        ? this.mesaObj.pagamentos
            .filter(
              (x) =>
                (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
                (this.comanda ? x.comanda_id == this.comanda.id : true)
            )
            .reduce((ret, vl) => {
              ret += vl.valor;
              return ret;
            }, 0)
        : 0;
    },
    pagoRateado() {
      return this.mesaObj.pagamentos
        ? this.mesaObj.pagamentos
            .filter((x) => x.ratear == 1)
            .reduce((ret, vl) => {
              ret += vl.valor;
              return ret;
            }, 0)
        : 0;
    },
    restante() {
      return this.total - this.pago;
    },
  },
  methods: {
    reabrir(){
      this.reabrirMesa();
    },
    addItem() {
      this.$emit("addItem");
    },
    getComandaNome(comanda, index) {
      if (comanda.nome.includes("|")) {
        return comanda.nome.split("|")[index];
      } else {
        return comanda.nome;
      }
    },
    addPgto() {
      this.$emit("addPgto");
    },
    removeTaxa() {
      console.log("removeTaxa 00");
      this.$emit("removeTaxa");
      this.$emit("removerTaxa", 123);
      this.$forceUpdate();
      console.log("removeTaxa 00 emitted");
    },
    fechar() {
      this.$emit("fecharMesa");
    },
  },
};
</script>

<style lang="scss" scoped>
.menor {
  font-size: 10px !important;
}
.selPGBt {
  cursor: pointer;
  transition: all 0.3s ease;
  padding-top: 10px;
  padding-bottom: 10px;
  // border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  &:hover {
    background-color: #f9f9f9;
    border-left: 2px solid #aaa;
    // border-right: 1px solid #ccc;
    padding-left: 15px;
  }
}
</style>
